import { useEffect, useState } from 'react';

const isExternal = (link: string) => {
  return (
    link.indexOf('duke-energy.com') === -1 &&
    link.indexOf('dukeenergy.smartcmobile.com') === -1 &&
    link.indexOf('internet.speedpay.com') === -1
  );
};

const useLinkHandler = () => {
  const [showModal, setShowModal] = useState(false);
  const [interceptLink, setInterceptLink] = useState('');

  useEffect(() => {
    const globalLinkClick = (event: any) => {
      if (event.target.tagName === 'A') {
        if (isExternal(event.target.href)) {
          event.preventDefault();

          setShowModal(true);
          setInterceptLink(event.target.href);
        } else {
          setShowModal(false);
          setInterceptLink('');
        }
      }
    };

    document.addEventListener('click', globalLinkClick);
    return () => {
      document.removeEventListener('click', globalLinkClick);
    };
  }, []);

  return { showModal, setShowModal, interceptLink, setInterceptLink };
};

export default useLinkHandler;
