import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router';
import Button from 'src/components/Common/@electron/Button';
import PageHeader from 'src/components/Common/@electron/PageHeader';
import AuthGuard from 'src/components/Common/AuthGuard';
import { BodyContextProvider } from 'src/components/Common/ContentWrapper/context';
import { JurisdictionGuard } from 'src/components/Common/JurisdictionGuard';
import NavWrapper from 'src/components/Common/NavWrapper';
import { NavContextProvider } from 'src/components/Common/NavWrapper/context';
import SvgLoader from 'src/components/Common/SvgLoader';
import useLinkHandler from 'src/lib/Hooks/useLinkHandler';
import useScrollTrack from 'src/lib/Hooks/useScrollTrack';
import { Route } from 'src/types/sitecore-jss';
import { ButtonGroup } from './components/Common/@electron/ButtonGroup';
import { Modal } from './components/Common/@electron/Modal';
import { DisabledPageFormsMessage } from './components/Common/DisabledFormsMessage';
import useRestoreScroll from './lib/Hooks/useRestoreScroll';
import { useAppContext } from './lib/Utils/Contexts/AppContext';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

/** Renders to the document `<head></head>` */
const Head = ({
  title = 'Building A Smarter Energy Future - Duke Energy',
  description = 'Everything you need to know about energy savings and information regarding energy service for your home from Duke Energy.',
  excludeFromIndex = false,
  repImg = null,
  siteName = 'jsspublic',
}) => {
  const { pathname } = useLocation();

  // react-helmet enables setting <head> contents, like title and OG meta tags
  return (
    <Helmet>
      <link
        rel="icon"
        type="image/svg"
        href={
          siteName === 'jsspng' ? '/dist/jsspublic/favicon-png.ico' : '/dist/jsspublic/favicon.ico'
        }
      />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://www.duke-energy.com${pathname}`} />
      <meta property="og:site_name" content="Duke Energy" />
      <meta property="og:image" content={repImg || '/-/media/images/share/de-logo-share.png'} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="apple-itunes-app"
        content={`app-id=1325217974, app-argument=https://www.duke-energy.com${pathname}`}
      />
      <meta name="twitter:site" content="@DukeEnergy"></meta>
      {excludeFromIndex && <meta name="robots" content="noindex"></meta>}
    </Helmet>
  );
};

const Layout = ({ route, children }: React.PropsWithChildren<{ route: Route }>) => {
  useRestoreScroll();
  useScrollTrack();
  const { showModal, interceptLink, setShowModal, setInterceptLink } = useLinkHandler();
  const { status } = useAppContext();

  const isDisabledPage = status.route?.placeholders['jss-public-header'].find(
    x => x.componentName === 'Global Toggle'
  )?.fields?.togglePage?.togglePage1;

  const hideFooterTagLine = route.fields?.HideTagline?.value || false;

  return (
    <>
      <Head
        title={route.fields?.['Meta Title']?.value}
        description={route.fields?.Description?.value}
        excludeFromIndex={route.fields?.['Exclude from Index']?.value}
        repImg={route.fields?.['Representative Image']?.value?.src}
        siteName={status.site?.name}
      />
      {/* root placeholder for the app, which we add components to using route data */}
      <NavContextProvider>
        <JurisdictionGuard.Intercept>
          <div id="maybe-hidden-content" className="flex-grow flex flex-col" tabIndex={-1}>
            <a
              className="btn btn-secondary fixed left-1/2 top-0 text-teal-darker bg-white transform -translate-x-1/2 -translate-y-full focus:translate-y-16 focus:z-modal"
              href="#main-content"
            >
              Skip to Content
            </a>
            <Placeholder name="jss-public-alerts" rendering={route} />
            <header className="z-header sticky md:relative top-0">
              <Placeholder name="jss-public-header" rendering={route} />
            </header>
            <main id="main-content" className="flex-grow flex flex-col">
              {!isDisabledPage && (
                <PageHeader
                  heading={route.fields?.Title}
                  subheading={route.fields?.Subhead}
                  isHidden={route?.fields?.['Hide Title']?.value}
                />
              )}
              <BodyContextProvider>
                <JurisdictionGuard.Mismatch>
                  <AuthGuard>
                    {isDisabledPage ? (
                      <DisabledPageFormsMessage />
                    ) : (
                      <Placeholder name="jss-public-main" rendering={route} />
                    )}
                  </AuthGuard>
                </JurisdictionGuard.Mismatch>
              </BodyContextProvider>
              {children}
            </main>
            <footer>
              <Placeholder
                name="jss-public-footer"
                rendering={route}
                hideTagLine={hideFooterTagLine}
              />
            </footer>
          </div>
          {/** dialog elements */}
          <NavWrapper />
        </JurisdictionGuard.Intercept>
      </NavContextProvider>
      {/** global modal for external link intercept */}
      <Modal
        onClose={() => {
          setShowModal(false);
          setInterceptLink('');
        }}
        isOpen={showModal}
        id="forgotUsername-close-modal"
        title={'You are now leaving Duke-Energy.com'}
        titleVariant="text-center"
        description={
          "You are now leaving Duke Energy's website and going to a website that is not operated by Duke Energy. We are not responsible for the availability of linked sites or their content. This inclues, but is not limited to privacy policies and terms and conditions."
        }
        descriptionVariant="text-center"
        variant="close-button"
        fromInterceptLink={true}
      >
        <ButtonGroup
          primary={
            <Button
              onClick={() => window.open(`${interceptLink}`, '_blank')}
              className="btn btn-primary btn-md btn-full mt-24"
            >
              {'Continue'}{' '}
              <span>
                <SvgLoader
                  aria-hidden="true"
                  className="text-white"
                  focusable="false"
                  name="ExternalLink"
                  sizeConstraints={false}
                />
              </span>
            </Button>
          }
          secondary={
            <button
              aria-disabled={false}
              className="btn btn-secondary btn-md btn-full mt-24"
              type="button"
              onClick={() => {
                setShowModal(false);
                setInterceptLink('');
              }}
            >
              {'Stay Here'}
            </button>
          }
        />
      </Modal>
    </>
  );
};

export default Layout;
